<template>
  <div>
    <section v-if="cssBanner != null" class="px-3 lg:px-6 bg-gray-500">
      <div class="mx-auto max-w-7xl md:h-22 h-22">
        <nav class="flex items-center h-24 select-none" x-data="{ showMenu: false }">
          <div
            class="relative flex flex-wrap items-center justify-between w-full h-24 mx-auto font-medium md:justify-center"
          >
            <router-link to="/" class="w-1/4 py-4 pl-6 pr-4 md:pl-4 md:py-0">
              <!-- <span
                class="p-1 text-xl font-black leading-none text-white select-none logo"
                ><span>MM Corporation</span><span class="text-gray-900"></span
              ></span> -->
              <img
                class="mx-auto"
                :src="`../../assets/images/mm-logo-white.png`"
                alt=""
                width="70"
              />
            </router-link>
            <div
              class="fixed top-0 left-0 z-40 items-center hidden w-full h-full p-3 text-xl bg-gray-900 bg-opacity-50 md:text-sm lg:text-base md:w-3/4 md:bg-transparent md:p-0 md:relative md:flex"
              :class="{ flex: showMenu, hidden: !showMenu }"
            >
              <div
                class="flex-col w-full h-auto h-full overflow-hidden bg-white rounded-lg select-none md:bg-transparent md:rounded-none md:relative md:flex md:flex-row md:overflow-auto"
              >
                <div
                  class="flex flex-col items-center justify-center w-full h-full mt-12 text-center text-gray-900 md:text-gray-400 md:w-2/3 md:mt-0 md:flex-row md:items-center"
                >
                  <router-link
                    to="/"
                    class="inline-block px-4 py-2 mx-2 font-medium text-left text-gray-900 md:text-white md:px-0 lg:mx-3 md:text-center"
                    >Home</router-link
                  >
                  <el-dropdown>
                    <a
                      href="#"
                      class="inline-block px-0 px-4 py-2 mx-2 font-medium text-left md:px-0 text-gray-900 hover:text-gray-900 md:hover:text-white lg:mx-3 md:text-center"
                      ><el-icon class="dropDownArrowMenu">
                        <ArrowDown />
                      </el-icon>
                      <span
                        class="font-medium text-left text-gray-300 md:text-white md:px-0 lg:mx-3 md:text-center"
                      >
                        Group of MM Corporation</span
                      ></a
                    >
                    <template #dropdown>
                      <AppMegaMenu />
                    </template>
                  </el-dropdown>
                </div>
                <div></div>
              </div>
            </div>
            <div
              @click="showMenu = !showMenu"
              class="absolute right-0 z-50 flex flex-col items-end w-10 h-10 p-2 mr-4 rounded-full cursor-pointer md:hidden hover:bg-gray-900 hover:bg-opacity-10 text-gray-100"
              :class="{ 'text-gray-400': showMenu, 'text-gray-100': !showMenu }"
            >
              <svg
                class="w-6 h-6"
                v-if="!showMenu"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
              <svg
                v-else
                class="w-6 h-6"
                :x-show="showMenu"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
        </nav>
        <div class="container py-32 mx-auto text-center sm:px-4"></div>
      </div>
    </section>
    <slot />
    <AppFooter />
    <!--menu drawer on mobile -->
    <el-drawer
      v-model="showMenu"
      size="100%"
      title="Menu"
      :with-header="false"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :show-close="true"
      :withHeader="true"
    >
      <div class="box-menu">
        <ul class="mobile-menu">
          <li v-for="(v, i) in sortData" :key="i" class="text-base">
            <router-link class="text-sm" @click="toggleNavbar()" :to="v.path">{{
              v.name
            }}</router-link>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import AppFooter from "../components/AppFooter";
import AppLogo from "../components/AppLogo.vue";
import AppMegaMenu from "../components/AppMegaMenu.vue";
import { ArrowDown, Film, Umbrella, Coffee, Cpu, FirstAidKit } from "@element-plus/icons";
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import data from "../data";
export default {
  name: "AppLayoutBusiness",
  components: {
    AppFooter,
    AppLogo,
    AppMegaMenu,
    ArrowDown,
    Film,
    Umbrella,
    Coffee,
    Cpu,
    FirstAidKit,
  },
  setup() {
    const showMenu = ref(false);
    const route = useRoute();
    var d = null;
    const cssBanner = ref(null);
    const sortData = data.unit.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    watchEffect(() => {
      if (route.path) {
        //console.log("pathhhh", route.path);
        const newData = data.unit.filter((v) => v.path === route.path);
        d = { ...newData[0] };
        // console.log(d);
        cssBanner.value = `background: linear-gradient(rgba(0, 0, 0, 0.637),rgba(46, 45, 45, 0.767),rgba(53, 53, 53, 0.103)),url('/assets/banner/${d.bg}');`;
        //console.log("layout", cssBanner);
      }
    });
    // const d = { ...newData[0] };

    function toggleNavbar() {
      showMenu.value = !showMenu.value;
    }
    return {
      showMenu,
      toggleNavbar,
      d,
      data,
      sortData,
      cssBanner,
    };
  },
};
</script>
<style scoped>
.dropDownArrowMenu {
  color: #ffffff;
}

/* 
**Setting menu Drawer
*/
.mobile-menu {
  text-align: left;
  overflow: hidden;
}
.mobile-menu > li {
  padding: 5px;
  color: #707070;
}
.mobile-menu > li > a {
  padding: 20px;
  font-size: 18px !important;
}
.mobile-menu > li > a:hover {
  color: #000000;
  /* text-decoration: underline; */
}
/* .bg-image {
  width: 100% !important;
  z-index: 100;
  background-color: #2e2e2e;
  background-position: center;
  background-position: center;
  object-fit: cover;
} */
</style>
