<template>
  <section class="bg-gray-100" v-if="newData">
    <!-- <pre>{{ newData }}</pre> -->
    <div class="max-w-screen-xl gap-0 px-4 py-12 mx-auto sm:px-6 lg:px-8">
      <div class="grid grid-cols-2 gap-0 md:grid-cols-6 lg:grid-cols-6">
        <div
          v-for="(d, i) in newData"
          :key="i"
          class="flex items-center justify-center col-span-1 md:col-span-2 lg:col-span-1"
        >
          <img
            @click="goToPage(d.path)"
            class="h-40 w-40 md:h-30 sm:h-20 md:w-30 sm:h-20 text-gray-500 fill-current imageEffect"
            :src="d.file"
            :alt="d.name"
            srcset=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useRouter } from "vue-router";
import data from "../data";
export default {
  name: "AppLogo",
  setup() {
    // console.log("data", data);
    const router = useRouter();
    const newData = data.unit.map((v) => {
      const obj = {
        code: v.code,
        file: `../assets/images/${v.file}`,
        name: v.name,
        path: v.path,
        web: v.web,
      };
      return obj;
    });
    function goToPage(url) {
      router.push(url);
    }
    return {
      data,
      newData,
      goToPage,
    };
  },
};
</script>

<style scoped>
.imageEffect {
  filter: grayscale(100%);
}
.imageEffect:hover,
.imageEffect:active,
.imageEffect:visited {
  filter: grayscale(5%);
  transition: filter 1s;
  cursor: pointer;
}
</style>
