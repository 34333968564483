<template>
	<div>
		<!-- <AppLayoutLinks /> -->
		<slot />
		<AppFooter />
	</div>
</template>
<script>
// import AppLayoutLinks from "@/layouts/AppLayoutLinks";
import AppFooter from "../components/AppFooter.vue";
export default {
	name: "AppLayoutContacts",
	components: {
		AppFooter,
	},
};
</script>
