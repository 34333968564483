import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import(/* webpackChunkName "home" */ "@/views/Home.vue"),
		meta: {
			layout: "AppLayoutDefault",
		},
	},
	{
		path: "/troya-indo-perkasa",
		name: "TroyaIndoPerkasa",
		component: () => import("../src/views/units/business.vue"),
		meta: {
			layout: "AppLayoutBusiness",
		},
	},
	{
		path: "/annarindo-mulia-perkasa",
		name: "AnnarindoMuliaPerkasa",
		component: () => import("../src/views/units/business.vue"),
		meta: {
			layout: "AppLayoutBusiness",
		},
	},
	// {
	// 	path: "/aman-amin-cafe",
	// 	name: "AmanAminCafe",
	// 	component: () => import("../src/views/units/business.vue"),
	// 	meta: {
	// 		layout: "AppLayoutBusiness",
	// 	},
	// },
	// {
	// 	path: "/tos-indonesia",
	// 	name: "TosIndonesia",
	// 	component: () => import("../src/views/units/business.vue"),
	// 	meta: {
	// 		layout: "AppLayoutBusiness",
	// 	},
	// },
	{
		path: "/trimedia-mulia-perkasa",
		name: "TrimediaMuliaPerkasa",
		component: () => import("../src/views/units/business.vue"),
		meta: {
			layout: "AppLayoutBusiness",
		},
	},
	// {
	// 	path: "/pijat-indonesia",
	// 	name: "PijatIndonesia",
	// 	component: () => import("../src/views/units/business.vue"),
	// 	meta: {
	// 		layout: "AppLayoutBusiness",
	// 	},
	// },
	{
		path: "/haiqa",
		name: "Haiqa",
		component: () => import("../src/views/units/business.vue"),
		meta: {
			layout: "AppLayoutBusiness",
		},
	},
	{
		path: "/logia-adi-karya",
		name: "Logia Adi Karya",
		component: () => import("../src/views/units/business.vue"),
		meta: {
			layout: "AppLayoutBusiness",
		},
	},
	{
		path: "/wrapeed",
		name: "Wrapeed",
		component: () => import("../src/views/units/business.vue"),
		meta: {
			layout: "AppLayoutBusiness",
		},
	},
	{
		path: "/contact",
		name: "Contact",
		component: () => import("../src/views/Contact.vue"),
		meta: {
			layout: "AppLayoutContact",
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
