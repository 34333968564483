import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import AppLayout from "@/layouts/AppLayout";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import "@element-plus/icons";
import "windi.css";
import "../src/css/custom.css";

createApp(App)
	.use(router)
	.use(ElementPlus)
	.component("AppLayout", AppLayout)
	.mount("#app");
