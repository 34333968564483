<template>
	<div id="app">
		<AppLayout>
			<router-view />
		</AppLayout>
	</div>
</template>

<style>
#app {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
