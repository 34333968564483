<template>
	<component :is="layout">
		<slot />
	</component>
</template>

<script>
import AppLayoutDefault from "./AppLayoutDefault";
import AppLayout404 from "./AppLayout404";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

export default {
	name: "AppLayout",
	setup() {
		const layout = shallowRef(AppLayoutDefault);
		const route = useRoute();
		watch(
			() => route.meta,
			async (meta) => {
				//console.log(route.path);
				try {
					const component = await require(`@/layouts/${meta.layout}.vue`);
					layout.value = component?.default || AppLayoutDefault;
				} catch (e) {
					// console.log("error tmplate", e);
					layout.value = AppLayout404;
				}
			}
		);
		return { layout };
	},
};
</script>
