<template>
  <el-dropdown-menu>
    <div class="megaMenu">
      <ul class="menu-desktop">
        <li>
          <router-link class="blockMenu" to="/troya-indo-perkasa"
            ><el-icon class="iconMenu">
              <Umbrella />
            </el-icon>
            <div>
              <span>Insurance Solutions</span
              ><span class="company-desc"><br />PT Troya Indo Perkasa</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link class="blockMenu" to="/annarindo-mulia-perkasa"
            ><el-icon class="iconMenu">
              <Cpu />
            </el-icon>
            <div>
              <span>IT Solutions</span
              ><span class="company-desc"><br />PT Annarindo Mulia Perkasa</span>
            </div>
          </router-link>
        </li>
        <!-- <li>
          <router-link class="blockMenu" to="/tos-indonesia"
            ><el-icon class="iconMenu">
              <FirstAidKit />
            </el-icon>
            <div>
              <span>Holistic Health</span
              ><span class="company-desc"><br />Tos Indonesia</span>
            </div>
          </router-link>
        </li> -->
      </ul>
      <ul class="menu-desktop">
        <!-- <li>
          <router-link class="blockMenu" to="/aman-amin-cafe"
            ><el-icon class="iconMenu">
              <Coffee />
            </el-icon>
            <div>
              <span>F&B</span><span class="company-desc"><br />Aman Amin Cafe</span>
            </div>
          </router-link>
        </li> -->
        <li>
          <router-link class="blockMenu" to="/haiqa"
            ><el-icon class="iconMenu">
              <GoodsFilled />
            </el-icon>
            <div>
              <span>Fashion</span><span class="company-desc"><br />Haiqa</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link class="blockMenu" to="/trimedia-mulia-perkasa"
            ><el-icon class="iconMenu">
              <Film />
            </el-icon>
            <div>
              <span>Digital Marketing</span
              ><span class="company-desc"><br />PT Trimedia Mulia Perkasa</span>
            </div>
          </router-link>
        </li>
        <!-- <li>
          <router-link class="blockMenu" to="/pijat-indonesia"
            ><el-icon class="iconMenu">
              <Refresh />
            </el-icon>
            <div>
              <span>Refleksiologi</span
              ><span class="company-desc"><br />Pijat Indonesia</span>
            </div>
          </router-link>
        </li> -->
      </ul>
      <ul class="menu-desktop">
        <li>
          <router-link class="blockMenu" to="/logia-adi-karya"
            ><el-icon class="iconMenu">
              <HomeFilled />
            </el-icon>
            <div>
              <span>Interir Design & Build</span
              ><span class="company-desc"><br />Logia Adi Karya</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link class="blockMenu" to="/wrapeed"
            ><el-icon class="iconMenu">
              <Van />
            </el-icon>
            <div>
              <span>Automotive</span
              ><span class="company-desc"><br />Wrapeed Indonesia</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </el-dropdown-menu>
</template>
<script>
import {
  ArrowDown,
  Film,
  Umbrella,
  Coffee,
  Cpu,
  FirstAidKit,
  Refresh,
  HomeFilled,
  GoodsFilled,
  Van,
} from "@element-plus/icons";
export default {
  name: "AppMegaMenu",
  components: {
    ArrowDown,
    Film,
    Umbrella,
    Coffee,
    Cpu,
    FirstAidKit,
    Refresh,
    HomeFilled,
    GoodsFilled,
    Van,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.dropDownArrowMenu {
  color: #ffffff;
}
.megaMenu {
  display: flex;
}
.menu-desktop > li {
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  line-height: 15px;
  width: 220px !important;
}
.menu-desktop > li > a {
  margin: 10px;
  font-size: 14px;
  color: #1b4a78;
  overflow: hidden;
}
.company-desc {
  font-size: 12px;
  color: #969696;
}
.menu-desktop > li > a:hover {
  color: #5581c4;
}

.iconMenu {
  /* color: #707070; */
  margin-right: 8px;
  margin-bottom: -7px;
  font-size: 25px;
  display: block;
}
.iconMenu:hover {
  color: #5581c4;
}
.blockMenu {
  display: flex;
}
</style>
