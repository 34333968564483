<template>
	<div class="pt-50 px-10">
		<!-- <AppLayoutLinks /> -->
		<h1 class="text-black-800 text-2xl">MM.Corporation</h1>
		<div class="max-w-sm mx-auto">
			<el-empty :description="`Ops Not Found Path: ${$route.path}`"></el-empty>
		</div>
		<h1 class="mb-10 text-4xl">404</h1>
		<router-link
			class="bg-blue-600 p-2 text-white rounded-md drop-shadow-md hover:bg-blue-700"
			to="/"
			>Back to Home</router-link
		>
	</div>
</template>
<script>
// import AppLayoutLinks from "@/layouts/AppLayoutLinks";

export default {
	name: "AppLayoutContacts",
	components: {
		// AppLayoutLinks,
	},
};
</script>
