<template>
	<div id="nav">
		<el-menu
			:default-active="activeIndex"
			class="el-menu-demo"
			mode="horizontal"
			@select="handleSelect"
		>
			<el-menu-item index="1"
				><router-link to="/">Home</router-link></el-menu-item
			>
			<el-sub-menu index="2">
				<template #title>Group</template>
				<el-menu-item index="2-1">Insurance</el-menu-item>
				<el-menu-item index="2-2">IT Solution</el-menu-item>
				<el-menu-item index="2-3">F&B</el-menu-item>
			</el-sub-menu>
		</el-menu>
	</div>
</template>

<script>
// export default {
// 	name: "AppLayoutLinks",
// };
import { defineComponent, ref } from "vue";

export default defineComponent({
	name: "AppLayoutLinks",
	setup() {
		const activeIndex = ref("1");
		const activeIndex2 = ref("1");
		const handleSelect = (key, keyPath) => {
			console.log(key, keyPath);
		};
		return {
			activeIndex,
			activeIndex2,
			handleSelect,
		};
	},
});
</script>

<style scoped>
#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #5c5c5c;
}
a {
	text-decoration: none;
}
</style>
