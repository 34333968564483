const data = {
	unit: [
		{
			segment: "Insurance Solutions",
			code: "troya",
			file: "troya.png",
			name: "Troya Indo Perkasa",
			path: "/troya-indo-perkasa",
			web: "http://troyaindo.co.id",
			bg: "umbrella.png",
			company: {
				about:
					"PT. Troya Indo Perkasa adalah Perusahaan Konsultasi Asuransi yang berdiri sejak 28 juli 2020. Perusahaan ini bertujuan memberikan solusi untuk konsultasi Asuransi Umum. Selain itu kami memberikan layanan Asuransi Umum seperti, Asuransi Kendaraan, Asuransi Properti, Asuransi Kesehatan, Asuransi Pengangkutan Barang, dan Asuransi Perangkat Elektronik.",
				visi:
					"Visi perusahaan kami adalah menjadikan perusahaan Jasa Konsultasi Asuransi terpercaya.",
				misi: [
					"Berkomitmen Tinggi dalam memberikan pelayanan dalam bidang asuransi",
					"Mengembangkan SDM (Sumber Daya Manusia) yang berkompetensi tinggi",
					"Memperluas jaringan Layanan Asuransi yang berstandar tinggi",
				],
				client: [],
				awards: [],
			},
			sosmed: {
				instagram: "troyaindo.co.id",
				tiktok: "@troyaindoperkasa",
				twitter: "indotroya",
				facebook: "profile.php?id=100069705125658",
				youtube: "channel/UC-uog1ftSPyM7uncttHGiyg",
			},
			contact: {
				email: "admin@troyaindo.co.id",
				hp: "0812 9955 6161",
				maps: "",
				address:
					"Ruko ITC BSD Blok R No.2 Jl.Pahlawan Seribu. BSD City Tangerang Selatan - Banten (15322).",
			},
		},
		{
			segment: "IT Solutions",
			code: "ama",
			file: "ama.png",
			name: "Annarindo Mulia Perkasa",
			path: "/annarindo-mulia-perkasa",
			web: "http://oceanesia.com",
			bg: "keyboard.png",
			company: {
				about:
					"PT Annarindo Mulia Perkasa adalah perusahaan yang bergerak dalam bidang teknologi, khususnya dalam pengembangan custom software yang melayani berbagai macam kebutuhan perusahaan.",
				visi:
					"Menjadi perusahaan penyedia layanan berbasis digital untuk semua lapisan masyarakat",
				misi: [
					"Mengembangkan layanan yang dapat membantu mempermudah mendapatkan informasi secara real time melalui digitalisasi.",
				],
				client: [],
				awards: [],
			},
			sosmed: {
				instagram: "annarindo",
				tiktok: "@annarindo",
				twitter: "",
				facebook: "",
				youtube: "",
			},
			contact: {
				email: "annarindo.dev@gmail.com",
				hp: "0852-8236-4633",
				maps: "https://goo.gl/maps/mz21y81yyoRVKQcYA",
				address:
					"Ruko ITC BSD Blok R No.2 Jl.Pahlawan Seribu. BSD City Tangerang Selatan - Banten (15322).",
			},
		},
		// {
		// 	segment: "Holistic Health",
		// 	code: "tos",
		// 	file: "tos.png",
		// 	name: "Tos Indonesia",
		// 	path: "/tos-indonesia",
		// 	web: "http://tosindonesia.com",
		// 	bg: "yoga.png",
		// 	company: {
		// 		about:
		// 			"Holistic Health Center atau TOS Merupakan tempat penyebuhan orang-orang yang mengalami luka batin dan mencari solusi melalui Meditasi, Hipnoterapi, Parenting, Konseling pernikahan dan Healing (Penyembuhan).",
		// 		visi:
		// 			"Mengenalkan kepada manusia kehidupan yang seutuhnya (holistic) melalui Therapy, Doa dan Meditasi dengan cara penyatuan Mind-Body-Spirit",
		// 		misi: [
		// 			"Agar semakin banyak manusia sehat lahir dan bathin serta menjadi kaya dengan dibukakan segala pintu rejeki dari Tuhan Yang Maha Kuasa. Serta menjadikan manusia agar lebih awet muda",
		// 		],
		// 		client: [],
		// 		awards: [],
		// 	},
		// 	sosmed: {
		// 		instagram: "tosindonesiaofficial",
		// 		tiktok: "",
		// 		twitter: "",
		// 		facebook: "",
		// 		youtube: "",
		// 	},
		// 	contact: {
		// 		email: "tosindonesiaadm@gmail.com",
		// 		hp: "081212318881",
		// 		maps: "",
		// 		address:
		// 			"Regency Melatimas C1 No.8a Kec. Pondok Jagung, Kec.Serpong Utara, Tangerang Selatan.",
		// 	},
		// },
		{
			segment: "Digital Marketing",
			code: "tmp",
			file: "tmp.png",
			name: "Trimedia Mulia Perkasa",
			path: "/trimedia-mulia-perkasa",
			web: "",
			bg: "sosmed.png",
			company: {
				about:
					"Trimedia adalah perusahaan yang bergerak di bidang industry kreatif dalam hal visual. Dan juga menangani dalam pembuatan konten-konten di social media.",
				visi:
					"To create good and interesting content for every one of our clients.",
				misi: [
					"To help create content for each client with the same goal of creating good and perfect art, and maintaining an aesthetic value of an image.",
				],
				client: [],
				awards: [],
			},
			sosmed: {
				instagram: "trimediamuliaperkasa",
				tiktok: "",
				twitter: "",
				facebook: "",
				youtube: "",
			},
			contact: {
				email: "Trimediamuliaperkasa@gmail.com",
				hp: "0812 1805 5350",
				maps: "https://goo.gl/maps/hh2sgdkcULsmi8KD6",
				address:
					"Regency Melatimas C1 no.8A Kec, Pd. Jagung, Kec. Serpong Utara, Kota Tangerang Selatan, Banten 15326",
			},
		},
		// {
		// 	segment: "F&B",
		// 	code: "aac",
		// 	file: "aac.png",
		// 	name: "Aman Amin Cafe",
		// 	path: "/aman-amin-cafe",
		// 	web: "",
		// 	bg: "menu.png",
		// 	company: {
		// 		about:
		// 			"Aman Amin Cafe menjual berbagai makanan dan minuman dengan rasa yang terbaik.",
		// 		visi: "",
		// 		misi: "",
		// 		client: [],
		// 		awards: [],
		// 	},
		// 	sosmed: {
		// 		instagram: "amanamincafe",
		// 		tiktok: "@amanamincafe",
		// 		twitter: "",
		// 		facebook: "",
		// 		youtube: "",
		// 	},
		// 	contact: {
		// 		email: "",
		// 		hp: "",
		// 		maps: "https://maps.app.goo.gl/yBN5TUXtxvLRLj6H9",
		// 		address:
		// 			"Jl. Klp. Lilin 11 Blok DC14 No.5, RT.1/RW.2, Curug Sangereng, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15810",
		// 	},
		// },
		// {
		// 	segment: "Refleksiologi",
		// 	code: "pi",
		// 	file: "pi.png",
		// 	name: "Pijat Indonesia",
		// 	path: "/pijat-indonesia",
		// 	web: "http://pijatindonesia.com",
		// 	bg: "pijat.png",
		// 	company: {
		// 		about:
		// 			"Pijat Indonesia merupakan suatu layanan yang bertujuan membantu dan mensejahterakan para terapis di seluruh Indonesia",
		// 		visi: "",
		// 		misi: [],
		// 		client: [],
		// 		awards: [],
		// 	},
		// 	sosmed: {
		// 		instagram: "indonesiapijat",
		// 		tiktok: "@pijatindonesia",
		// 		twitter: "",
		// 		facebook: "profile.php?id=100072354532749",
		// 		youtube: "",
		// 	},
		// 	contact: {
		// 		email: "indonesiapijat@gmail.com ",
		// 		hp: "081212995518",
		// 		maps: "",
		// 		address: "",
		// 	},
		// },
		{
			segment: "Interior Design & Build",
			code: "loka",
			file: "loka.png",
			name: "Logia Adi Karya",
			path: "/logia-adi-karya",
			web: "https://studiologia.com/",
			bg: "lokabg.png",
			company: {
				about:
					"PT Logia Adi Karya beridiri sejak Agustus 2021, kami bergerak dalam bidang interior architecture design and build untuk memberikan jasa terbaik kami yang mengutamakan detail-detail pada desain yang sering kali tidak diperhatikan selain estetika: details, design rationale, dan design empathy. \n\n\n Kami selalu menjalankan prinsip-prinsip desain mendasar seperti: ergonomics, design principles, dan sustainability, disertai dengan sistem produksi yang mengedepankan efisiensi dan keahlian untuk memproduksi barang-barang custom dengan presisi dan mengurangi bahan terbuang, menggandeng pengrajin lokal dan bahan lokal sehingga menciptakan ‘circular economy’ yang pada akhirnya memberikan kontribusi sosial dan lingkungan.",
				visi:
					"Profesionalitas dan kepercayaan kedalam dunia industri design and build tanah air. ",
				misi: [
					"Menjunjung tinggi kejujuran, transparansi, dan kualitas baik desain dan produksi.",
				],
				client: [
					{
						category: "Residential",
						list: ["High Profile Clients", "Developers"],
					},
					{
						category: "Institusi",
						list: [
							"Raffles Design Institute",
							"Sanggar Sibernetika",
							"Asuransi Reliance Indonesia",
							"Polar Engine",
							"Government Institution",
						],
					},
					{
						category: "Retail",
						list: [
							"Passion Prive",
							" The Wangsa",
							"Gudhas Village",
							"Pactolian, etc.",
						],
					},
				],
				awards: [
					"Asia Young Designer Award, Singapore",
					"Design Excellence Award, Singapore",
					"HDB Placemaking Award, Singapore.",
					"Off Grid Sustainable Architecture, Italy.",
				],
			},
			sosmed: {
				instagram: "",
				tiktok: "",
				twitter: "",
				facebook: "",
				youtube: "",
			},
			contact: {
				email: "ferdycheng@studiologia.com",
				hp: "+6281299889945",
				maps: "",
				address:
					"Ruko ITC BSD, Blok R No. 2, Jl. Palawan Seribu, BSD, Tangerang Selatan. Banten",
			},
		},
		{
			segment: "Fashion",
			code: "haiqa",
			file: "haiqa.png",
			name: "Haiqa",
			path: "/haiqa",
			web: "http://haiqaofficial.com",
			bg: "haiqa.png",
			company: {
				about:
					"HAIQA adalah brand fashion muslim yang berdiri sejak tahun 2021. Desain pakaian HAIQA dirancang untuk membuat Anda tampil elegan dan percaya diri. HAIQA percaya bahwa semua wanita spesial dan bisa berkarya. Kami memastikan kualitas terbaik di semua koleksi pakaian HAIQA yang tentunya membuat Anda merasa percaya diri, elegan dalam setiap cerita kehidupan Anda.",
				visi:
					"Menjadi brand fashion muslim yang trendy dengan penampilan soleha, elegan dan modis.",
				misi: [
					"Memproduksi baju kualitas terbaik dengan desain muslimah yang trendy, Memberikan pelayanan yang baik dan professional kepada customer dan Membantu meningkatkan ekonomi dan kualitas hidup masyarakat melalui industri fashion.",
				],
				client: [],
				awards: [],
			},
			sosmed: {
				instagram: "haiqaofficial",
				tiktok: "@haiqaofficial",
				twitter: "",
				facebook: "haiqaofficial",
				youtube: "channel/UCRoBMDMq-IK8dLiH8TWrPVg",
			},
			marketplace: {
				tokopedia: "haiqaofficial",
				shopee: "haiqaofficial",
			},
			contact: {
				email: "adminhaiqa@gmail.com ",
				hp: "0811-1623-389",
				maps: "",
				address: "Jl. Raya Serpong no.1B dan 1C",
			},
		},
		{
			segment: "Automotive",
			code: "wrapeed",
			file: "wrapeed.png",
			name: "Wrapeed Indonesia",
			path: "/wrapeed",
			web: "",
			bg: "wrapeed.png",
			company: {
				about: "Workshop Vehicle Exterior Modification.",
				visi:
					"Menjadi workshop pilihan utama masyarakat dalam hal modifikasi exterior kendaraan dan berperan penting dalam dunia modifikasi Indonesia",
				misi: [
					"Membangun kualitas modifikasi exterior dengan bahan terbaik dan SDM yang handal",
					"Memahami beragam kebutuhan pelanggan dengan ikut berpartisipasi dalan event/organisasi otomotif dari berbagai kalangan",
					"Meningkatkan kepercayaan pelanggan dalam memberikan pelayanan terbaik dan hasil maksimal",
				],
				client: [],
				awards: [],
			},
			sosmed: {
				instagram: "wrapeed_id",
				tiktok: "",
				twitter: "",
				facebook: "",
				youtube: "",
			},
			contact: {
				email: "",
				hp: "0813-1780-5831",
				maps: "https://maps.app.goo.gl/SjbVGoJztTAwjjuh7?g_st=iw",
				address:
					"Perumahan Alam Sutera, Ruko ASTC 10G no.2-3, Jl. Alam Sutera Boulevard No. 01, Pakulonan, Kec Serpong Utara, Kota Tangerang Selatan - Banten 15325",
			},
		},
	],
};
export default data;
